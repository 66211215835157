import React from "react";

function Graph() {
  return (
    <iframe
      src="./graph.html"
      title="HTML Content"
      width="100%"
      height="600px"
      style={{ border: "none" }}
    ></iframe>
  );
}
export default Graph;
