import React from 'react';
import './Pagination.css';

const Pagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageClick = (page) => {
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const generatePageNumbers = () => {
    const pages = [];

    // Always include the first 3 pages
    for (let i = 1; i <= Math.min(3, totalPages); i++) {
      pages.push(i);
    }

    // Include 3 pages around the current page
    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
      if (i > 3 && i < totalPages - 2) {
        pages.push(i);
      }
    }

    // Always include the last 3 pages
    for (let i = Math.max(totalPages - 2, 4); i <= totalPages; i++) {
      pages.push(i);
    }

    // Remove duplicates and sort
    return [...new Set(pages)].sort((a, b) => a - b);
  };

  return (
    <div className="pagination">
      <button
        className="prev-page"
        disabled={currentPage === 1}
        onClick={handlePreviousPage}
      >
        Previous
      </button>

      <div className="page-numbers">
        {generatePageNumbers().map((number) => (
          <button
            key={number}
            className={`page-number ${
              currentPage === number ? 'active' : ''
            }`}
            onClick={() => handlePageClick(number)}
          >
            {number}
          </button>
        ))}
      </div>

      <button
        className="next-page"
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;