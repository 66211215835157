const customStyles = {
  control: (provided, state) => ({
    ...provided,
    padding: '0',
    minHeight: '35px',
    minWidth: '160px',
    fontSize: '14px',
    border: state.isFocused ? '1px solid #50a14f' : '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
    color: '#333',
    boxShadow: state.isFocused ? '0 0 4px rgba(80, 161, 79, 0.3)' : 'none',
    transition: 'border-color 0.2s ease',
    '&:hover': {
      borderColor: '#50a14f',
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '5px',
    marginTop: '4px',
    backgroundColor: '#f9f9f9',
    color: '#333',
    border: '1px solid #ccc',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#e6f7e4' : '#f9f9f9',
    color: '#333',
    fontSize: '14px',
    padding: '8px 12px',
    '&:hover': {
      backgroundColor: '#e6f7e4',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#666',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? '#50a14f' : '#333', // Color of the arrow
    padding: '4px', // Adjust padding if needed
    '&:hover': {
      color: '#50a14f', // Color on hover
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none', // Hides the vertical separator line
  }),
};


export default customStyles;