import './App.css';
// src/App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './AppRouter';
import Navbar from './Navbar';

function App() {
  return (
    <Router>
      <div className="app-container">
        <Navbar />
        <div className="content">
          <AppRouter />
        </div>
      </div>      
    </Router>
  );
}

export default App;
