import React, { useState, useEffect } from 'react';
import './Kitchen.css';

const Kitchen = () => {
  const [brands, setBrands] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [filteredLocations, setFilteredLocations] = useState([]);

  // Fetch brands and locations from the API on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://av.voicebite.ai:8008/config');
        const data = await response.json();

        setBrands(data.brands || []);        // Set brands from API data
        setLocations(data.locations || []);   // Set locations from API data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Handlers for dropdown changes
  const handleBrandChange = (event) => {
    const brand = event.target.value;
    setSelectedBrand(event.target.value);
    setFilteredLocations(
      locations.filter(location => location.brand === brand)
    );
  };

  return (
    <div className="kitchen">
      <h1>Kitchen</h1>

      <div className="dropdowns">
        <div className="dropdown">
          <label htmlFor="brand">Brand:</label>
          <select
            id="brand"
            value={selectedBrand}
            onChange={handleBrandChange}
          >
            <option value="">Select a Brand</option>
            {brands.map((brand, index) => (
              <option key={index} value={brand}>
                {brand}
              </option>
            ))}
          </select>
        </div>

        <div className="dropdown">
          <label htmlFor="location">Location:</label>
          <select id="location">
            <option value="">Select a Location</option>
            {filteredLocations.map((location, index) => (
              <option key={index} value={location.checkmate_id}>
                {location.checkmate_id} - {location.address}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default Kitchen;
