// src/utils/utils.js

export function timeAgo(timestamp) {
  const validTimestamp = timestamp.slice(0, 23); 
  const date = new Date(validTimestamp);
  const now = new Date();
  const nowUtc = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
    
  const differenceInMilliseconds = nowUtc - date.getTime();
  const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
  const minutes = Math.floor(
    (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
  );

  if (hours > 0) {
    return `${hours} hour${hours !== 1 ? "s" : ""}${
      minutes > 0 ? ` and ${minutes} minute${minutes !== 1 ? "s" : ""}` : ""
    } ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
  } else {
    return "Just now";
  }
}

export function convertToCST(timestampUTC) {  
  const validTimestamp = timestampUTC.slice(0, 23);
  const utcDate = new Date(validTimestamp);
  const now = new Date();
  const cstDate = new Date(utcDate.getTime() - now.getTimezoneOffset() * 60 * 1000);
  
  // Format the date in CST timezone
  const formattedDate = cstDate.toLocaleString("en-US", {
    timeZone: "America/Chicago",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return `${formattedDate} CST`;
}

export function convertUTCDateToLocalDate(time) {
  let date = new Date(time);
  let newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

  let offset = date.getTimezoneOffset() / 60;
  let hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;   
}

export function getDialogStyle(typ, dialogIndex) {
  let color;

  switch (typ) {
    case "AI":
      color = "#50a14f";
      break;
    case "STT":
    case "XX":
      color = "#0d0d0d";
      break;
    case "OAI":
      color = "#986801";
      break;
    default:
      color = dialogIndex % 2 === 0 ? "#986801" : "#0d0d0d";
  }

  return {
    color,
    textDecoration: typ === "XX" ? "line-through" : "none",
  };
}
