import React, { useEffect, useRef, useState, useCallback } from "react";
import WaveSurfer from "wavesurfer.js";
import { FaPlay, FaPause } from "react-icons/fa";
import "./AudioPlayer.css";

const AudioPlayer = ({ audioUrl, clickedDialog }) => {
  const waveformRef = useRef(null);
  const wavesurferRef = useRef(null);
  const [regions, setRegions] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);

  console.log("audioUrl", audioUrl);

  useEffect(() => {
    if (!wavesurferRef.current) {
      wavesurferRef.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: "#ddd",
        progressColor: "#dc2b2b",
        height: 80,
        responsive: true,
      });

      wavesurferRef.current.load(audioUrl);

      wavesurferRef.current.on("ready", () => {
        const duration = wavesurferRef.current.getDuration();
        const decodedData = wavesurferRef.current.getDecodedData();

        if (decodedData) {
          const extractedRegions = extractRegions(
            decodedData.getChannelData(0),
            duration
          );
          setRegions(extractedRegions);
          regions.forEach((region) => {
            wavesurferRef.current.addRegion({
              start: region.start,
              end: region.end,
              color: "rgba(0, 123, 255, 0.3)", // Adjust color for visual clarity
            });
          });
        }
      });

      wavesurferRef.current.on("error", (error) => {
        console.error("WaveSurfer error:", error);
      });
    }
  }, [audioUrl, regions]);

  const togglePlayPause = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsPlaying(!isPlaying);
    wavesurferRef.current.playPause();
  };

  const jumpToTime = useCallback(
    (item) => {
      let time =
        item.words.length > 0 ? item.words[0].start / 10000000 : item.t;

      // Find the closest region to the given time
      const closestRegion = regions.reduce((closest, region) => {
        const startDistance = Math.abs(region.start - time);
        const endDistance = Math.abs(region.end - time);
        const closestDistance = Math.min(
          Math.abs(closest.start - time),
          Math.abs(closest.end - time)
        );
        const regionDistance = Math.min(startDistance, endDistance);

        return regionDistance < closestDistance ? region : closest;
      }, regions[0]);

      const newTime = closestRegion.start;
      console.log("Jumping to closest region start:", newTime);

      if (wavesurferRef.current) {
        const duration = wavesurferRef.current.getDuration();
        if (time <= duration) {
          wavesurferRef.current.setTime(newTime);
          if (!wavesurferRef.current.isPlaying()) {
            wavesurferRef.current.play();
          }
        } else {
          console.error("Specified time exceeds audio duration");
        }
      }
    },
    [regions]
  );

  useEffect(() => {
    if (clickedDialog) {
      jumpToTime(clickedDialog);
    }
  }, [clickedDialog, jumpToTime]);

  // console.log("regions", regions);

  return (
    <>
      <div className="audio-player">
        <button onClick={(e) => togglePlayPause(e)} className="play-pause-btn">
          {isPlaying ? <FaPause/> : <FaPlay/>}
        </button>
        <div ref={waveformRef} className="waveform"></div>
      </div>
    </>
  );
};

const extractRegions = (audioData, duration) => {
  const minValue = 0.001; // Adjust this to fine-tune silence detection
  const minSilenceDuration = 0.8; // Minimum duration to consider as silence
  const mergeDuration = 0.2; // Merge silence regions closer than this duration
  const scale = duration / audioData.length;
  const silentRegions = [];

  let start = 0;
  let end = 0;
  let isSilent = false;

  for (let i = 0; i < audioData.length; i++) {
    if (audioData[i] < minValue) {
      if (!isSilent) {
        start = i;
        isSilent = true;
      }
    } else if (isSilent) {
      end = i;
      isSilent = false;
      if (scale * (end - start) > minSilenceDuration) {
        silentRegions.push({
          start: scale * start,
          end: scale * end,
        });
      }
    }
  }

  const mergedRegions = [];
  let lastRegion = null;
  for (let i = 0; i < silentRegions.length; i++) {
    if (lastRegion && silentRegions[i].start - lastRegion.end < mergeDuration) {
      lastRegion.end = silentRegions[i].end;
    } else {
      lastRegion = silentRegions[i];
      mergedRegions.push(lastRegion);
    }
  }

  let regions = [];
  let lastEnd = 0;
  for (let i = 0; i < mergedRegions.length; i++) {
    regions.push({
      start: lastEnd,
      end: mergedRegions[i].start,
    });
    lastEnd = mergedRegions[i].end;
  }

  return regions;
};

export default AudioPlayer;
