import React, { useEffect, useState } from "react";
import "./FineTuneOutput.css";

const FineTuneOutput = ({ recording, clickedDialog }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [cartContent, setCartContent] = useState(
    recording?.cart
  );

  useEffect(() => {
    if (clickedDialog) {
      setCartContent(clickedDialog?.cart);
    }
  }, [clickedDialog]);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleContentChange = (event) => {
    setCartContent(event.target.value);
  };

  return (
    <div className="fine-tune-container">
      <div className="fine-tune">
        <div className="fine-tune-header">
          <h4>Fine-tune Output (cart)</h4>
          <span className="pointer edit-link" onClick={handleEditClick}>
            {isEditing ? "Save" : "Edit"}
          </span>
        </div>

        {cartContent ? (
          <div>
            {isEditing ? (
              <textarea
                value={cartContent}
                onChange={handleContentChange}
                rows="10"
                cols="50"
              />
            ) : (
              cartContent.split("\n")?.map((line, index) => (
                <div key={index} style={{ whiteSpace: "pre" }}>
                  {line}
                </div>
              ))
            )}
          </div>
        ) : (
          <div>No cart information available</div>
        )}
      </div>
    </div>
  );
};

export default FineTuneOutput;
