// src/AppRouter.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import Arena from "./pages/arena/Arena";
import Kitchen from "./pages/kitchen/Kitchen";
import Graph from "./pages/graph/Graph";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Arena />} />
      <Route path="/arena" element={<Arena />} />
      <Route path="/kitchen" element={<Kitchen />} />
      <Route path="/graph" element={<Graph />} />
    </Routes>
  );
};

export default AppRouter;
